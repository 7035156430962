import React from "react";
import {Layout} from "../Layout";
import ProductBanner from "../product_banners/product_banners";
import Banner from "../../imgs/bg_empleos.jpg";
import './busqueda.css';

const text = `Sumate al desafío de trabajar en la <span class="highlightsbold">Empresa N°1 del Mercado.</span>`;
const imgUrl = process.env.GATSBY_BASE_URL + 'static/bg_empleos-4a3922eb4a1040eacdb4c1435cfea801.jpg'

const EmpleosLayout = ({pageData, children}) => {
    const page = (
        <>
            <ProductBanner
                bannerContainerClassName="bannerReporte"
                bannerTrasparecy="bannerMask"
                productTitle="Se parte de Balanz"
                bannerImage={Banner}
                bannerImageClassName="ReportBannerImage"
                textContainerClassName="textContainer"
                bannerText={text}
                bannerTextClassName="bannerTextInversiones"
                bannerImageMobile={Banner}
                mobile={false}
            />

            {children}

        </>
    )
    return (
        <main>
            <Layout title={pageData.title} description={pageData.description} img={imgUrl} childrem={page}
                    category={"trabaja_con_nosotros"}></Layout>
        </main>
    )
}

export default EmpleosLayout;