import React from "react";
import {graphql} from "gatsby";
import TrabajaConNosotros from "../../components/empleos/trabaja-con-nosotros";
import EmpleosLayout from "../../components/empleos/EmpleosLayout";
import EmpleosList from "../../components/empleos/EmpleosList";

const pageData = {
    title: 'Trabajá con nosotros',
    description: 'Potenciá tu carrera laboral ingresando a la compañía líder del Mercado de Capitales. Conocé nuestras búsquedas de empleos y postulate.'
};

const TrabajaConNosotrosPage = ({data}) => {
    return <EmpleosLayout pageData={pageData}>
        <EmpleosList jobPositions={data.allSearches.nodes} />
    </EmpleosLayout>
}

export const query = graphql`
  query {
    allSearches {
      nodes {
        description_task
        id_search
        name
        position_name
        profile_description
        sector
        seniority_name
        timestamp
        timestamp_approve
      }
    }
  }
`

export default TrabajaConNosotrosPage