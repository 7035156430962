import React, {useEffect, useState} from "react";
import {Link} from "gatsby";
import {Button, Card, Carousel, Col, Container, Row} from "react-bootstrap";
import Icon from "../icon/icon";
import Select from 'react-select'

import Mail from "../../imgs/share_mail.svg";
import Linkedin from "../../imgs/share_linkedin.svg";
import Whatsapp from "../../imgs/share_whatsapp.svg";

import BannerGreatPlaceMujeres from "../../imgs/banner-great-place-mujeres-2.jpg";
import BannerGreatPlaceMilennials from "../../imgs/banner-great-place-3.jpg";
import BannerGreatPlacePersonas from "../../imgs/banner-great-place-1.jpg";
import './trabaja-con-nosotros.css';
import Pagination from "../pagination/pagination";
import slugify from "slugify";
import axios from "axios";
import truncate from "html-truncate";
import { SalWrapper } from "react-sal";

const TrabajaConNosotros = ({jobPositions}) => {
    const [data, setData] = useState(jobPositions);
    const [arrayDepartments, setArrayDepartments] = useState([]);
    const [totalData, setTotalData] = useState(0)
    const [departaments, setDepartaments] = useState([]);
    const [departamentSelected, setDepartamentSelected] = useState([])
    const [jobs, setJobs] = useState(jobPositions)
    const [index, setIndex] = useState(0);
    const [pageSize] = useState(12);
    const [idGeneric, setIdGeneric] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const baseUrl = `${process.env.GATSBY_BASE_URL}trabaja-con-nosotros/`;

    const selectDepartaments = (SearchData) => {
        const dData = [];
        SearchData.forEach(d => {
            let search_departament = dData.find(dD => dD.value === d.id_departament);
            if (typeof search_departament === 'undefined' && null !== d.id_departament && null !== d.departament) {
                dData.push({value: d.id_departament, label: d.departament})
            }
        })
        setDepartaments(dData)

    }

    const setSearchPositionsData = serverData => {
        selectDepartaments(serverData);
    }

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const preparePage = (data) => {
        data = data.filter(d => {
            let check = true;
            if (departamentSelected.length > 0) {
                check = departamentSelected.includes(d.id_departament);
            }
            return check;
        })

        setTotalData(data.length)

        if (data.length > 0) {
            const firstPageIndex = (currentPage - 1) * pageSize;
            const lastPageIndex = firstPageIndex + pageSize;
            setJobs(data.slice(firstPageIndex, lastPageIndex))
        } else {
            setJobs([])
        }
    }

    useEffect(() => {
        setSearchPositionsData(data);
    }, [data]);

    useEffect(() => {
        axios.get(process.env.GATSBY_RRHH_API_URL + '/v1/positions/corporate')
            .then(({data}) => {
                setData(data.filter(d => d.id_departament !== 0));

                const genericSearch = data.filter(d => d.id_departament === 0);
                if (genericSearch.length > 0) setIdGeneric(genericSearch[0].id_search);
            })
            .catch(error => {
                console.log(error)
            })
    }, []);

    const getAllDepartments = () => {
        axios.get(process.env.GATSBY_RRHH_API_URL + '/v1/positions/departaments/public')
      .then (resp => {
                setArrayDepartments(resp.data.data)
            })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
        getAllDepartments()
    }, []);
    let departamentsData
    if(arrayDepartments.length > 0){
        departamentsData = arrayDepartments.map((departamento) => {
        return {value: departamento.id_departament, label: departamento.departament}
        })

    }


    /*useEffect(() => {
        axios.get(process.env.GATSBY_RRHH_API_URL + '/v1/positions/generic/corporate')
            .then(response => {
                setIdGeneric(response.data.id)
            })
            .catch(error => {
                console.log(error)
            })
    }, []);*/

    useEffect(() => {
        preparePage(data)
    }, [currentPage, data, departamentSelected])


    const handleChange = (newValue, actionMeta) => {
        setDepartamentSelected(newValue.map((obj) => obj.value));
    };

    /*
     * 22-08-09 Gonza
     * Por ahora lo dejo comentado porque el SSR no finaliza
     */
    // preparePage(data);

    const page = (
        <>
            <section className="fullwidth seccion-empleos pb-0">
                <Container>
                    <Row className="pt-5 pb-1">
                        <p className="pb-4 base-text-2 title-page">Últimas búsquedas de Empleo <span className="markstrong"></span></p>
                    </Row>
                    <Row className='with-line aling-items-center'>
                        <Col md="6" lg="6" sm="12" xs="12">
                            <Select onChange={handleChange} isMulti placeholder='Filtrar por departamento' options={departamentsData}/>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="fullwidth listado-empleos">
                <Container>
                    {jobs.length > 0 ? (
                        <>
                            <Row>
                                {jobs.map((d, i) => {
                                    return (
                                        <Col sm="12" md="6" lg="3" key={i}>

                                            <Card border="light" className="card-empleo">
                                                <Card.Body>
                                                    <span className="topic">
                                                        <strong>{d.seniority_name}</strong>
                                                    </span>
                                                    <Link
                                                        to={"/trabaja-con-nosotros/" + d.id_search + "-" + slugify(d.position_name, {lower: true})}>
                                                        <h4 title={d.position_name}
                                                            className="title">{d.position_name}</h4>
                                                        <p title={d.description_task}
                                                           className="type">{truncate(d.description_task,90)}</p>
                                                    </Link>

                                                    <div className="share ">
                                                        <span>Compartir</span>
                                                        <div className="d-flex align-items-center pt-2">
                                                            <div className="icon">
                                                                <Icon
                                                                    src={Mail}
                                                                    alt="Mail-icon"
                                                                    className="Mail-footer footer-icons"
                                                                    href={`mailto:?subject=UX/UIDesigner || Balanz&body=${baseUrl}busqueda?q="${d.id}`}
                                                                />
                                                            </div>
                                                            <div className="icon pl">
                                                                <Icon
                                                                    src={Whatsapp}
                                                                    alt="Whatsapp-icon"
                                                                    className="whatsapp-footer footer-icons"
                                                                    data-action="share/whatsapp/share"
                                                                    href={`https://api.whatsapp.com/send?text=${encodeURIComponent(baseUrl + `busqueda?q="${d.id}`)}`}
                                                                    target="_blank"
                                                                />
                                                            </div>
                                                            <div className="icon pl">
                                                                <Icon
                                                                    src={Linkedin}
                                                                    alt="Linkedin-icon"
                                                                    className="linkedin-footer footer-icons"
                                                                    href={`https://www.linkedin.com/shareArticle?mini=true&url=${baseUrl}busqueda?q="${d.id}&title=Busqueda: \nUX/UI Designer&summary=UX/UIDesigner&source=${baseUrl}`}
                                                                    target="_blank"
                                                                />
                                                            </div>
                                                        </div>


                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                                })}
                            </Row>

                            <Row className="py-5 text-center">
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={totalData}
                                    pageSize={pageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </Row>
                        </>
                    ) : (
                        <Row>
                            <Col xs="12">

                                <h4><strong>En este momento, no hay búsquedas abiertas para este departamento. Por favor, dejanos tu CV</strong></h4>
                            </Col>
                        </Row>
                    )}

                </Container>

            </section>

            {idGeneric && (
                <section className="fullwidth pt-0 seccion-postularse">
                    <Container>
                        <h2 className='primary-color title'>Envíanos tu C.V.</h2>
                        <p className="base-text mb-5">Completá tus datos y adjuntá tu C.V para tenerte en cuenta en
                            futuras búsquedas.</p>
                        <Button target="_blank" href={process.env.GATSBY_RRHH_SEARCH_URL + 'aplicar/' + idGeneric}
                            type="button" variant="secondary">Postular</Button>
                    </Container>
                </section>
            )}


            <Carousel controls={false} activeIndex={index} onSelect={handleSelect} indicators={false}
                className="section-carousel mb-5" interval={6000}>
                <Carousel.Item className='item'>
                    <img className="w-100" src={BannerGreatPlaceMujeres} alt="BannerGreatPlaceMujeres"/>
                </Carousel.Item>
                <Carousel.Item className='item'>
                    <img className="w-100" src={BannerGreatPlaceMilennials} alt="BannerGreatPlaceMilennials"/>
                </Carousel.Item>
                <Carousel.Item className='item'>
                    <img className="w-100" src={BannerGreatPlacePersonas} alt="BannerGreatPlacePersonas"/>
                </Carousel.Item>
            </Carousel>

            <Col xs="12" className="d-flex justify-content-center">
                <div>
                    <h6>Búsqueda laboral equitativa Ley 6471-21. El empleador sólo podrá solicitarle la información estrictamente necesaria para el desempeño en el trabajo que se le ofrece.</h6> <br/>
                </div>
            </Col>
        </>
    )
    return page
}

export default TrabajaConNosotros;